import Vue from 'vue'
import VueRouter from 'vue-router'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'

Vue.use(VueRouter)

const routes = [
  // Logout
  {
    path: '/logout',
    name: 'logout',
    component: () => import('@/views/Logout.vue'),
    meta: {
      layout: 'full',
    },
  },
  // Logout End
  // Login
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  // Login End
  // Forgot Password
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import('@/views/ForgotPassword.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  // Forgot Password End
  {
    path: '/',
    name: 'orders',
    component: () => import('@/views/Admin/Orders/list.vue'),
    meta: {
      pageTitle: 'Orders',
      breadcrumb: [
        {
          text: 'Orders',
          active: true,
        },
      ],
    },
  },
  {
    path: '/orders/:id/edit',
    name: 'edit-order',
    component: () => import('@/views/Admin/Orders/edit.vue'),
    meta: {
      pageTitle: 'EditOrder',
      breadcrumb: [
        {
          text: 'EditOrder',
          active: true,
        },
      ],
    },
  },
  {
    path: '/customers',
    name: 'customers',
    component: () => import('@/views/Admin/Customers/list.vue'),
    meta: {
      pageTitle: 'Customers',
      breadcrumb: [
        {
          text: 'Customers',
          active: true,
        },
      ],
    },
  },
  {
    path: '/customers/:id',
    name: 'customer-detail',
    component: () => import('@/views/Admin/Customers/detail.vue'),
    meta: {
      pageTitle: 'CustomerDetail',
      breadcrumb: [
        {
          text: 'CustomerDetail',
          active: true,
        },
      ],
    },
  },
  {
    path: '/add-customer',
    name: 'customer-add',
    component: () => import('@/views/Admin/Customers/add.vue'),
    meta: {
      pageTitle: 'AddNewCustomer',
      breadcrumb: [
        {
          text: 'AddNewCustomer',
          active: true,
        },
      ],
    },
  },
  {
    path: '/customers/:id/edit',
    name: 'customer-edit',
    component: () => import('@/views/Admin/Customers/edit.vue'),
    meta: {
      pageTitle: 'EditCustomer',
      breadcrumb: [
        {
          text: 'EditCustomer',
          active: true,
        },
      ],
    },
  },
  {
    path: '/add-courier',
    name: 'AddNewCouriers',
    component: () => import('@/views/Admin/Couriers/add.vue'),
    meta: {
      pageTitle: 'AddNewCouriers',
      breadcrumb: [
        {
          text: 'AddNewCouriers',
          active: true,
        },
      ],
    },
  },
  {
    path: '/couriers',
    name: 'Couriers',
    component: () => import('@/views/Admin/Couriers/list.vue'),
    meta: {
      pageTitle: 'Couriers',
      breadcrumb: [
        {
          text: 'Couriers',
          active: true,
        },
      ],
    },
  },
  {
    path: '/couriers/:id/edit',
    name: 'EditCourier',
    component: () => import('@/views/Admin/Couriers/edit.vue'),
    meta: {
      pageTitle: 'EditCourier',
      breadcrumb: [
        {
          text: 'EditCourier',
          active: true,
        },
      ],
    },
  },
  // Statistics Start
  {
    path: '/statistics',
    name: 'Statistics',
    component: () => import('@/views/Admin/Statistics/list.vue'),
    meta: {
      pageTitle: 'Statistics',
      breadcrumb: [
        {
          text: 'Statistics',
          active: true,
        },
      ],
    },
  },
  // Settings Start
  {
    path: '/settings',
    name: 'settings',
    component: () => import('@/views/Admin/Settings/edit.vue'),
    meta: {
      pageTitle: 'Settings',
      breadcrumb: [
        {
          text: 'Settings',
          active: true,
        },
      ],
    },
  },
  // Settings End
  // Statistics End
  // Stock Start
  {
    path: '/stock',
    name: 'Stock',
    component: () => import('@/views/Admin/Stock/list.vue'),
    meta: {
      pageTitle: 'Stock',
      breadcrumb: [
        {
          text: 'Stock',
          active: true,
        },
      ],
    },
  },
  // Stock End
  // Employees Start
  {
    path: '/employees',
    name: 'Employees',
    component: () => import('@/views/Admin/Employees/list.vue'),
    meta: {
      pageTitle: 'Employees',
      breadcrumb: [
        {
          text: 'Employees',
          active: true,
        },
      ],
    },
  },
  {
    path: '/employees/:id/edit',
    name: 'EditEmployee',
    component: () => import('@/views/Admin/Employees/edit.vue'),
    meta: {
      pageTitle: 'EditEmployee',
      breadcrumb: [
        {
          text: 'EditEmployee',
          active: true,
        },
      ],
    },
  },
  {
    path: '/add-employee',
    name: 'Employees add',
    component: () => import('@/views/Admin/Employees/add.vue'),
    meta: {
      pageTitle: 'Employees',
      breadcrumb: [
        {
          text: 'Employees',
          active: true,
        },
      ],
    },
  },
  // Employees End
  // Categories Start
  {
    path: '/categories',
    name: 'Categories',
    component: () => import('@/views/Admin/Categories/list.vue'),
    meta: {
      pageTitle: 'Categories',
      breadcrumb: [
        {
          text: 'Categories',
          active: true,
        },
      ],
    },
  },
  {
    path: '/categories/:id/edit',
    name: 'EditCategory',
    component: () => import('@/views/Admin/Categories/edit.vue'),
    meta: {
      pageTitle: 'EditCategory',
      breadcrumb: [
        {
          text: 'EditCategory',
          active: true,
        },
      ],
    },
  },
  {
    path: '/add-category',
    name: 'AddCategory',
    component: () => import('@/views/Admin/Categories/add.vue'),
    meta: {
      pageTitle: 'Categories',
      breadcrumb: [
        {
          text: 'Categories',
          active: true,
        },
      ],
    },
  },
  // Categories End
  // Cars Start
  {
    path: '/cars',
    name: 'Cars',
    component: () => import('@/views/Admin/Cars/list.vue'),
    meta: {
      pageTitle: 'Cars',
      breadcrumb: [
        {
          text: 'Cars',
          active: true,
        },
      ],
    },
  },
  {
    path: '/cars/:id/edit',
    name: 'EditCar',
    component: () => import('@/views/Admin/Cars/edit.vue'),
    meta: {
      pageTitle: 'Edit Car',
      breadcrumb: [
        {
          text: 'Edit Car',
          active: true,
        },
      ],
    },
  },
  {
    path: '/add-car',
    name: 'AddCar',
    component: () => import('@/views/Admin/Cars/add.vue'),
    meta: {
      pageTitle: 'Add Car',
      breadcrumb: [
        {
          text: 'Add Car',
          active: true,
        },
      ],
    },
  },
  // Cars End
  // Stocks Start
  {
    path: '/warehouses',
    name: 'Warehouses',
    component: () => import('@/views/Admin/Stocks/list.vue'),
    meta: {
      pageTitle: 'Stocks',
      breadcrumb: [
        {
          text: 'Stocks',
          active: true,
        },
      ],
    },
  },
  {
    path: '/warehouses/:id/edit',
    name: 'EditStock',
    component: () => import('@/views/Admin/Stocks/edit.vue'),
    meta: {
      pageTitle: 'Edit warehouse',
      breadcrumb: [
        {
          text: 'Edit warehouse', 
          active: true,
        },
      ],
    },
  },
  {
    path: '/add-warehouse',
    name: 'AddStock',
    component: () => import('@/views/Admin/Stocks/add.vue'),
    meta: {
      pageTitle: 'Add warehouse',
      breadcrumb: [
        {
          text: 'Add warehouse', 
          active: true,
        },
      ],
    },
  },
  // Stocks End
  // OCI Start
  {
    path: '/oci',
    name: 'optimal-car-inventories',
    component: () => import('@/views/Admin/OptimalCarInventories/list.vue'),
    meta: {
      pageTitle: 'Optimal Car Inventories',
      breadcrumb: [
        {
          text: 'OptimalCarInventories',
          active: true,
        },
      ],
    },
  },
  // OCI End
  // Products Start
  {
    path: '/products',
    name: 'Products',
    component: () => import('@/views/Admin/Products/list.vue'),
    meta: {
      pageTitle: 'Products',
      breadcrumb: [
        {
          text: 'Products',
          active: true,
        },
      ],
    },
  },
  {
    path: '/products/:id/edit',
    name: 'EditProduct',
    component: () => import('@/views/Admin/Products/edit.vue'),
    meta: {
      pageTitle: 'Edit Product',
      breadcrumb: [
        {
          text: 'Edit Product',
          active: true,
        },
      ],
    },
  },
  {
    path: '/add-product',
    name: 'AddProduct',
    component: () => import('@/views/Admin/Products/add.vue'),
    meta: {
      pageTitle: 'Add Product',
      breadcrumb: [
        {
          text: 'Add Product',
          active: true,
        },
      ],
    },
  },
  // Products End
  // StockManager Stock
  {
    path: '/stock-managment',
    name: 'stock-manager',
    component: () => import('@/views/StockManager/Stock/index.vue'),
    meta: {
      pageTitle: 'Stock',
      breadcrumb: [
        {
          text: 'Stock',
          active: true,
        },
      ],
    },
  },
  // StockManager Stock End
  // Dispatcher
  // Orders
  {
    path: '/dispatcher/orders',
    name: 'orders-dispatcher',
    component: () => import('@/views/Dispatcher/Orders/list.vue'),
    meta: {
      pageTitle: 'Orders',
      breadcrumb: [
        {
          text: 'Orders',
          active: true,
        },
      ],
    },
  },
  {
    path: '/orders/add-order',
    name: 'add-order',
    component: () => import('@/views/Admin/Orders/add.vue'),
    meta: {
      pageTitle: 'Add Order',
      breadcrumb: [
        {
          text: 'Add Order',
          active: true,
        },
      ],
    },
  },
  // Orders End
  // Cash register
  {
    path: '/cash-register',
    name: 'cash-register',
    component: () => import('@/views/Dispatcher/CashRegister/index.vue'),
    meta: {
      pageTitle: 'Cash Register',
      breadcrumb: [
        {
          text: 'Cash Register',
          active: true,
        },
      ],
    },
  },
  // Cash register End
  // Cars
  {
    path: '/dispatcher/cars',
    name: 'cars-dispatcher',
    component: () => import('@/views/Dispatcher/Cars/list.vue'),
    meta: {
      pageTitle: 'Cars',
      breadcrumb: [
        {
          text: 'Cars',
          active: true,
        },
      ],
    },
  },
  // Cars End
  // Dispatcher End
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  // Redirect to login if not logged in
  if (!isLoggedIn && (to.name !== 'login' && to.name !== 'forgot-password' && to.path !== '/.well-known/apple-app-site-association' )) { 
    return next({ name: 'login' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.roles[0] : null))
  }

  // Router guards
  if (isLoggedIn) {
    const userData = getUserData()
    // Admin
    if (userData.role === 'ROLE_ADMIN' && (['cars-dispatcher', 'cash-register', 'orders-dispatcher', 'stock-manager'].includes(to.name))) {
      next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
    }
    // Dispatcher
    if (userData.role === 'ROLE_DISPATCHER' && !(['stock-manager', 'customers', 'customer-detail', 'customer-add', 'customer-edit', 'cars-dispatcher', 'cash-register', 'orders-dispatcher', 'add-order', 'edit-order', 'add-order', 'settings', 'logout', 'error-404'].includes(to.name))) {
      next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
    }
    // Stock manager
    if (userData.role === 'ROLE_STOCK_MANAGER' && !(['stock-manager', 'settings', 'logout', 'error-404'].includes(to.name))) {
      next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
    }
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
