<template>
  <div id="app">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>
<script>
const LayoutHorizontal = () => import('@/layouts/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/LayoutFull.vue')

export default {
  components: {
    LayoutFull,
    LayoutHorizontal,
  },
  computed: {
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      return 'layout-horizontal'
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/style.scss';
</style>
