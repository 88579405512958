import JWT from './JWT'

export const isUserLoggedIn = () => localStorage.getItem('userData') && localStorage.getItem(JWT.config.storageTokenKeyName)

export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

export const getHomeRouteForLoggedInUser = userRole => {
  if (userRole === 'ROLE_ADMIN') return `/`
  if (userRole === 'ROLE_DISPATCHER') return `/dispatcher/orders`
  if (userRole === 'ROLE_STOCK_MANAGER') return `/stock-managment`
  return { name: 'login' }
}